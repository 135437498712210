import React, { useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import Image, { StaticImageData } from "next/image";

interface IconProps {
  src: StaticImageData;
  name: string;
}

const Icon: React.FC<IconProps> = ({ src, name }) => {
  const [isHovered, setIsHovered] = useState(false);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left - rect.width;
    const y = event.clientY - rect.top - rect.height;
    mouseX.set(x);
    mouseY.set(y);
  };

  const handleMouseLeave = () => {
    mouseX.set(0);
    mouseY.set(0);
    setIsHovered(false);
  };

  const xPos = useTransform(mouseX, [-100, 100], [-20, 20]);
  const yPos = useTransform(mouseY, [-100, 100], [-20, 20]);

  return (
    <motion.div
      className="relative cursor-pointer w-8"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      style={{ x: xPos, y: yPos }}
    >
      <motion.div
        className="absolute w-3 h-3 rounded-full bg-white left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2"
        variants={{
          initial: { opacity: 0 },
          hover: { opacity: 1 },
        }}
        animate={isHovered ? "hover" : "initial"}
        transition={{ duration: 1.5 }}
      ></motion.div>
      <motion.p
        variants={{
          initial: { opacity: 0 },
          hover: { opacity: 1 },
        }}
        animate={isHovered ? "hover" : "initial"}
        transition={{ duration: 1.5 }}
        className="text-white absolute w-50 -bottom-7 text-lg"
      >
        {name}
      </motion.p>
      <motion.div
        variants={{
          initial: { opacity: 1 },
          hover: { opacity: 0 },
        }}
        animate={isHovered ? "hover" : "initial"}
        transition={{ duration: 1.5 }}
      >
        <Image
          src={src}
          alt={name}
          style={{
            maxWidth: "100%",
            height: "auto"
          }} />
      </motion.div>
    </motion.div>
  );
};

export default Icon;
