import React, { useState } from "react";
import wLogo from "../public/white logo.webp";
import Image from "next/image";
import wtwitter from "../public/white twitter.webp";
import wfacebook from "../public/white facebook.webp";
import wlinkedin from "../public/white linked in.webp";
import wyoutube from "../public/white youtube.webp";
import mail from "../public/mail-01.webp";
import call from "../public/call.webp";
import { motion, useMotionValue } from "framer-motion";
import Icon from "./ui/icon";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import winstagram from "../public/winstagram.webp";
import Link from "next/link";
import { version } from "../lib/packageVersion";

function Footer() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";

  const socialMedias = [
    {
      src: wfacebook,
      alt: "facebook",
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=61554448557944&mibextid=ZbWKwL",
    },
    {
      src: wlinkedin,
      alt: "linkedin",
      name: "LinkedIn",
      href: "https://eg.linkedin.com/company/mint-ops",
    },
    {
      src: winstagram,
      alt: "instagram",
      name: "instagram",
      href: "https://www.instagram.com/mint0ps?igsh=dmMycDd6MWZ1YzEx",
    },
  ];

  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const handleHover = (e: any) => {
    const { top, left, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left - width) / 2;
    const y = (e.clientY - top - height) / 2;
    setHoverPosition({ x, y });
  };

  return (
    <div className="bg-gradient-to-b from-blue-900 to-slate-950 pt-24 pb-6 sm:px-36 px-4 font-montserrat">
      <div className=" flex justify-between flex-wrap gap-y-9">
        <div className="lg:w-[40%] md:w-[70%] w-full">
          <Image
            src={wLogo}
            alt="mint-ops"
            className="w-[200px] mb-2 max-w-full h-auto"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <p
            className={`text-white font-normal ${
              lang === "ar" ? "pr-4 lg:pl-16 pl-18" : "pl-4 lg:pr-16 pr-18"
            }  mb-7 opacity-80`}
          >
            {" "}
            {t("new_content")}{" "}
          </p>
          <div
            className={`${
              lang === "ar" ? "pr-4" : "pl-4"
            } flex gap-x-6 items-center`}
          >
            {socialMedias.map((media, index) => (
              <Link href={media.href} target="_blank" key={index}>
                <Icon src={media.src} name={media.name} />
              </Link>
            ))}
          </div>
        </div>
        <div className="md:w-[20%] w-[50%]">
          <h3 className="text-white text-xl font-semibold border-b-4 w-fit pb-4 mb-4 border-white">
            {" "}
            {t("pages")}
          </h3>
          <ul className={`${lang === "ar" ? "pr-4" : "pl-4"} list-none`}>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              <Link href={`/${lang}`}>{t("home")}</Link>
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("services")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("solution")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("marketplace")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              <Link href={`/${lang}/contact-us`}>{t("contact_us")}</Link>
            </li>
          </ul>
        </div>
        <div className="lg:w-[20%] w-[50%]">
          <h3 className="text-white text-xl font-semibold border-b-4 w-fit pb-4 mb-4 border-white">
            {t("services")}
          </h3>
          <ul className={`${lang === "ar" ? "pr-4" : "pl-4"}  list-none`}>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("aws_consulting")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("Devops_consulting")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("Ui_Ux")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("web_and_mobile")}
            </li>
            <li
              className={`text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 after:absolute after:w-[7px] after:h-[7px] after:bg-white after:top-1/2 ${
                lang === "ar" ? "after:-right-[16px]" : "after:-left-[16px]"
              } after:rounded-full after:-translate-y-1/2`}
            >
              {t("security_hardening")}
            </li>
          </ul>
        </div>
        <div className="lg:w-[20%] md:w-[50%] w-full">
          <h3 className="text-white text-xl  font-semibold border-b-4 w-fit pb-4 mb-4 border-white">
            {t("contact")}
          </h3>
          <ul>
            <li className="text-white opacity-70 cursor-pointer text-base font-normal relative mb-5 flex gap-x-3 items-center">
              <Image
                src={call}
                alt="call"
                className="max-w-full h-auto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <span dir="ltr">+971 547 119 952</span>
            </li>
            <li className="text-white opacity-70 cursor-pointer text-base font-normal relative mb-5 flex gap-x-3 items-center">
              <Image
                src={call}
                alt="call"
                className="max-w-full h-auto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <span dir="ltr">+966 55 063 9598</span>
            </li>

            {/* <li className="text-white opacity-70 cursor-pointer text-base font-normal relative mb-5 flex gap-x-3 items-center">
              <Image
                src={call}
                alt="call"
                className="max-w-full h-auto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <span dir="ltr">+20 106 566 6548</span>
            </li> */}
            <li className="text-white opacity-70 cursor-pointer text-base font-normal relative mb-5 flex gap-x-3 items-center">
              <Image
                src={mail}
                alt="mail"
                className="max-w-full h-auto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <span>sales@mint-ops.com</span>
            </li>
            <li className="text-white opacity-70 cursor-pointer text-base font-normal relative mb-3 flex gap-x-3 items-center">
              <Image
                src={mail}
                alt="mail"
                className="max-w-full h-auto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <span>support@mint-ops.com</span>
            </li>
          </ul>
        </div>
      </div>
      <h4 className="text-white text-base font-normal mt-12 text-center">
        {t("copy_right")}, version: {version}
      </h4>
    </div>
  );
}

export default Footer;
